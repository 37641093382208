import AIOTabComponent from "@components/AIOTab.component";
import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AsyncSelectWithSearchComponent from "@components/input/AsyncSelectWithSearch.component";
import AvatarPickerComponent from "@components/input/AvatarPicker.component";
import SubscriptionPeriodSwitch from "@components/subscriptions/SubscriptionPeriod.switch";
import { Colors } from "@constants/colors.constant";
import { alpha, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { companiesService } from "@store/ai-o/companies";
import { affiliatesService } from "@store/entities/afiliates";
import {
  DEFAULT_REGISTER_USER,
  ManageRegisterUserModel,
  registerUsersService,
  RegisterUserTypeEnum,
  RegisterUserTypeEnumFunctions,
} from "@store/registerUsers";
import { subscriptions, SubscriptionTypeEnum } from "@store/subscriptions";
import { Pages, RoutesUnAuth } from "@utils/routes.utils";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { finalize, of, switchMap } from "rxjs";
import SubscriptionSmallColumnComponent from "@components/subscriptions/SubscriptionSmallColumn.component";
import { stripeService } from "@store/stripe/stripe.service";
import StripeModal from "@components/modal/Stripe.modal";
import { StripePaymentIntentSecret } from "@store/stripe/stripe.model";

const MainContainer = styled(Stack)((props: { ismobile?: "true" | "false" }) => ({
  backgroundColor: Colors.greyBg,
  width: "100%",
  height: "100%",
  marginLeft: props.ismobile === "true" ? "0px" : undefined,
  marginRight: props.ismobile === "true" ? "0px" : undefined,
}));

const SecondaryContainer = styled(Stack)({
  width: "100%",
  maxWidth: "916px",
  padding: "65px 0",
});

const SteppedContainer = styled(Stack)({
  backgroundColor: Colors.white,
  borderRadius: "15px",
  width: "100%",
  position: "relative",
});

const SteppedContent = styled(Stack)({
  width: "100%",
  backgroundColor: Colors.white,
});

const TextLink = styled(Typography)({
  textDecoration: "underline",
  cursor: "pointer",
});

const SignUp = () => {
  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState<number>(1);
  const [user, setUser] = useState<ManageRegisterUserModel>(DEFAULT_REGISTER_USER);

  const [showStripe, setShowStripe] = useState<StripePaymentIntentSecret | false>(false);

  const [openSelectAvatar, setOpenSelectAvatar] = useState<boolean>(false);
  const [registerLoading, setRegisterLoading] = useState(false);

  const [acceptPolicy, setAcceptPolicy] = useState<boolean>(false);

  const [captchaAnswer, setCaptchaAnswer] = useState<string>("");
  const [captchaQuestion, setCaptchaQuestion] = useState<{ num1: number; num2: number; operator: "+" | "-" | "×" }>({
    num1: 0,
    num2: 0,
    operator: "+",
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptPolicy(event.target.checked);
  };

  const totalStep = 3;

  const freemiumSubscription = subscriptions.find((s) => s.type === SubscriptionTypeEnum.FREEMIUM);
  const proSubscription = subscriptions.find((s) => s.type === SubscriptionTypeEnum.PROFESSIONAL);
  const companySubscription = subscriptions.find((s) => s.type === SubscriptionTypeEnum.COMPANY);

  useEffect(() => {
    const redirect = searchParams.get("redirect");
    if (redirect?.includes("subscription_")) {
      setUser((state) => ({
        ...state,
        selectedSubscriptionType: redirect.split("subscription_")[1] as SubscriptionTypeEnum,
      }));
    }
  }, [searchParams]);

  useEffect(() => {
    generateNewCaptcha();
  }, []);

  const generateNewCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const operators = ["+", "-", "×"] as const;
    const operator = operators[Math.floor(Math.random() * operators.length)];
    setCaptchaQuestion({ num1, num2, operator });
    setCaptchaAnswer("");
  };

  const validateCaptcha = () => {
    let expectedResult: number;
    switch (captchaQuestion.operator) {
      case "+":
        expectedResult = captchaQuestion.num1 + captchaQuestion.num2;
        break;
      case "-":
        expectedResult = captchaQuestion.num1 - captchaQuestion.num2;
        break;
      case "×":
        expectedResult = captchaQuestion.num1 * captchaQuestion.num2;
        break;
      default:
        expectedResult = 0;
    }
    return Number(captchaAnswer) === expectedResult;
  };

  if (!freemiumSubscription || !proSubscription || !companySubscription) return null;

  const showPostalCode = process.env.REACT_APP_COUNTRY === "US";

  const errors: { [key: string]: any } = {
    step1: {
      firstname: getFieldError(user.firstname, FieldValidationType.REQUIRED_STRING),
      lastname: getFieldError(user.lastname, FieldValidationType.REQUIRED_STRING),
      position: getFieldError(user.position, FieldValidationType.REQUIRED_STRING),
      email: getFieldError(user.email, FieldValidationType.REQUIRED_EMAIL),
      ...(showPostalCode ? { postalCode: getFieldError(user.postalCode, FieldValidationType.REQUIRED_STRING) } : {}),
    },
    step2: {
      allInOneAffiliate: user.type === RegisterUserTypeEnum.AIO && getFieldError(user.allInOneAffiliate, FieldValidationType.REQUIRED_SELECT_ITEM),
      AI_OCompany: user.type === RegisterUserTypeEnum.AI_O && getFieldError(user.AI_OCompany, FieldValidationType.REQUIRED_SELECT_ITEM),
      missingCompanyName:
        user.type === RegisterUserTypeEnum.NEITHER_AIO_NOR_AI_O && getFieldError(user.missingCompanyName, FieldValidationType.REQUIRED_STRING),
    },
    step3: {
      selectedSubscriptionType: user.selectedSubscriptionType === companySubscription.type && !user.companyOffersNb && !user.companyUnlimitedOffers ? t("errors.required") : undefined,
      captcha: !captchaAnswer ? t("errors.required") : !validateCaptcha() ? t("errors.invalidCaptcha") : undefined,
    },
  };

  const updateUser = (name: string) => (value: any) => setUser((state) => ({ ...state, [name]: value }));

  const handleConfirm = () => {
    if (step >= totalStep) {
      setRegisterLoading(true);
      registerUsersService
        .createRegisterUser(user)
        .pipe(
          switchMap((newUser) => {
            if (newUser.selectedSubscriptionType === SubscriptionTypeEnum.PROFESSIONAL) {
              return stripeService.getProSubClientSecret({
                userIds: [newUser.email],
                isYearly: !newUser.isMonthly,
                registerUserEmail: newUser.email,
                registerUserFirstname: newUser.firstname,
                registerUserLastname: newUser.lastname,
                registerUserPostalCode: newUser.postalCode,
              });
            }
            return of(undefined);
          }),
          finalize(() => setRegisterLoading(false))
        )
        .subscribe({
          next: (stripeSecret) => {
            if (!!stripeSecret) {
              setShowStripe(stripeSecret);
            } else {
              enqueueSnackbar(t("auth.registering.success.created"), { variant: "success" });
              navigate(`/${RoutesUnAuth.LOGIN}`);
            }
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
      return;
    }

    setStep(step + 1);
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const getStep = () => {
    switch (step) {
      case 1:
        return (
          <SteppedContent spacing="23px" padding={breakpointDownMD ? "10px 20px" : "37px 30px"}>
            <Stack direction={"row"} alignItems={"center"} spacing={0.8}>
              <Typography fontSize="20px" fontWeight={700} lineHeight={1.25}>
                {t("auth.registering.yourInformation")}
              </Typography>
              <Typography fontSize="14px" fontWeight={500} lineHeight={1.25} color={Colors.greyLight}>
                {`(${step}/${totalStep})`}
              </Typography>
            </Stack>
            <Stack spacing={breakpointDownMD ? "20px" : "55px"} width={"100%"} alignItems={"center"}>
              <Stack alignItems="center" mb={breakpointDownMD ? undefined : "15px"}>
                <IconButton
                  aria-label="avatar-circle"
                  onClick={() => setOpenSelectAvatar(true)}
                  sx={{
                    background:
                      user.pictureFile || user.picture
                        ? `linear-gradient(${alpha(Colors.black, 0.4)}, ${alpha(Colors.black, 0.4)}), url(${
                            user.pictureFile ? URL.createObjectURL(user.pictureFile) : user.picture
                          })`
                        : "",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}>
                  {<img alt="" src="/images/camera.svg" />}
                </IconButton>
              </Stack>
              <Stack width={"100%"} spacing={"30px"} maxWidth={"642px"}>
                <Stack direction={breakpointDownMD ? "column" : "row"} alignItems={"center"} width={"100%"} spacing={"30px"}>
                  <Box width={"100%"}>
                    <AIOTextfieldComponent
                      required
                      title={t("userDetails.lastname")}
                      placeholder={t("userDetails.lastname")}
                      value={user.lastname ?? ""}
                      error={errors.lastname}
                      onChange={updateUser("lastname")}
                      fullWidth
                    />
                  </Box>
                  <Box width={"100%"}>
                    <AIOTextfieldComponent
                      required
                      title={t("userDetails.firstname")}
                      placeholder={t("userDetails.firstname")}
                      value={user.firstname ?? ""}
                      error={errors.firstname}
                      onChange={updateUser("firstname")}
                      fullWidth
                    />
                  </Box>
                </Stack>
                <Stack direction={breakpointDownMD ? "column" : "row"} alignItems={"center"} width={"100%"} spacing={"30px"}>
                  <Box width={"100%"}>
                    <AIOTextfieldComponent
                      required
                      title={t("userDetails.position")}
                      placeholder={t("userDetails.position")}
                      value={user.position ?? ""}
                      error={errors.position}
                      onChange={updateUser("position")}
                    />
                  </Box>
                  <Box width={"100%"}>
                    {showPostalCode ? (
                      <AIOTextfieldComponent
                        required
                        title={t("userDetails.postalCode")}
                        placeholder={t("userDetails.postalCode")}
                        value={user.postalCode ?? ""}
                        error={errors.postalCode}
                        pattern={/[0-9]+/}
                        onChange={updateUser("postalCode")}
                      />
                    ) : (
                      <AIOTextfieldComponent
                        required
                        title={t("userDetails.email")}
                        placeholder={t("userDetails.email")}
                        value={user.email ?? ""}
                        error={errors.email}
                        onChange={updateUser("email")}
                      />
                    )}
                  </Box>
                </Stack>
                {showPostalCode && (
                  <Stack direction={breakpointDownMD ? "column" : "row"} alignItems={"center"} width={"100%"} spacing={"30px"}>
                    <Box width={"100%"}>
                      <AIOTextfieldComponent
                        required
                        title={t("userDetails.email")}
                        placeholder={t("userDetails.email")}
                        value={user.email ?? ""}
                        error={errors.email}
                        onChange={updateUser("email")}
                      />
                    </Box>
                    <Box width={"100%"}></Box>
                  </Stack>
                )}
              </Stack>

              <Stack direction={"row"} alignItems={"center"} spacing={"4px"}>
                <Checkbox checked={acceptPolicy} onChange={handleCheckboxChange} />
                <Typography fontWeight={300}>{t("auth.registering.agree")}</Typography>
                <TextLink color="primary" onClick={() => window.open(Pages.PRIVACY, "_blank")}>
                  {t("global.policy")}
                </TextLink>
                <Typography fontWeight={300}>{t("auth.registering.andThe")}</Typography>
                <TextLink color="primary" onClick={() => window.open(Pages.CGU, "_blank")}>
                  {t("global.cgu")}
                </TextLink>
              </Stack>
            </Stack>
          </SteppedContent>
        );
      case 2:
        return (
          <SteppedContent spacing="23px" padding={breakpointDownMD ? "10px 0px" : "37px 30px"}>
            <Stack direction={"row"} alignItems={"center"} spacing={0.8} px={breakpointDownMD ? "20px" : undefined}>
              <Typography fontSize="20px" fontWeight={700} lineHeight={1.25}>
                {t("auth.registering.yourCompany")}
              </Typography>
              <Typography fontSize="14px" fontWeight={500} lineHeight={1.25} color={Colors.greyLight}>
                {`(${step}/${totalStep})`}
              </Typography>
            </Stack>
            <AIOTabComponent
              tabs={[t("auth.registering.tabs.onAllInOne"), t("auth.registering.tabs.onAIDotO"), t("auth.registering.tabs.neither")]}
              handleChange={(value) => {
                setUser((state) => ({
                  ...state,
                  type: RegisterUserTypeEnumFunctions.registerUserTypes[value],
                  allInOneAffiliate: undefined,
                  affiliateSiblings: undefined,
                  AI_OCompany: undefined,
                  AI_OCommentary: undefined,
                  missingCompanyName: undefined,
                  missingCompanyCommentary: undefined,
                }));
              }}
              value={RegisterUserTypeEnumFunctions.registerUserTypes.indexOf(user.type!)}
              variant={breakpointDownMD ? "scrollable" : undefined}
            />
            <Stack width={"100%"} mt="40px" px={breakpointDownMD ? "20px" : undefined}>
              {user.type === RegisterUserTypeEnum.AIO && (
                <Stack spacing="21px">
                  <Stack spacing="10px">
                    <Typography fontSize="12px" color={Colors.secondaryText}>
                      {t("auth.registering.selectCompany")}
                    </Typography>
                    <Box width="350px" maxWidth={breakpointDownMD ? "100%" : undefined}>
                      <AsyncSelectWithSearchComponent
                        placeholder={t("auth.registering.search")}
                        getOptions={affiliatesService.searchAffiliates}
                        minimumSearchLength={3}
                        handleChange={(value) => {
                          updateUser("allInOneAffiliate")(value);
                          affiliatesService
                            .searchAffiliates("", value?.data.client.id)
                            .then((res) =>
                              updateUser("affiliateSiblings")(res.filter((x) => x.value !== value?.value).map((x) => ({ checked: false, item: x })))
                            );
                        }}
                        value={user.allInOneAffiliate ?? undefined}
                      />
                    </Box>
                  </Stack>
                  {!!user?.affiliateSiblings?.length && (
                    <Stack spacing="5px" pt="16px">
                      <Typography fontSize="12px" color={Colors.secondaryText}>
                        {t("auth.registering.otherAffiliates")}
                      </Typography>
                      <Box pl="10px">
                        <Grid container spacing="10px">
                          {user?.affiliateSiblings?.map((x) => (
                            <Grid key={x.item.value} item>
                              <FormControlLabel
                                onChange={(evt, checked) => {
                                  updateUser("affiliateSiblings")(
                                    user?.affiliateSiblings?.map((y) => (y.item.value === x.item.value ? { ...y, checked } : y))
                                  );
                                }}
                                control={
                                  <Checkbox
                                    size="small"
                                    icon={<img width="13px" src="/images/icon_checkbox_empty_black.svg" alt="unchecked" />}
                                    checkedIcon={<img width="13px" src="/images/icon_checkbox_filled_black.svg" alt="checked" />}
                                  />
                                }
                                checked={x.checked}
                                label={
                                  <Typography ml="10px" fontWeight="500">
                                    {x.item.label}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Stack>
                  )}
                </Stack>
              )}
              {user.type === RegisterUserTypeEnum.AI_O && (
                <Stack spacing="21px">
                  <Stack spacing="10px">
                    <Typography fontSize="12px" color={Colors.secondaryText}>
                      {t("auth.registering.selectCompany")}
                    </Typography>
                    <Stack direction="row" alignItems="flex-end" spacing="20px">
                      <Box width="350px">
                        <AsyncSelectWithSearchComponent
                          placeholder={t("auth.registering.search")}
                          getOptions={companiesService.searchCompanies}
                          minimumSearchLength={3}
                          handleChange={(value) => updateUser("AI_OCompany")(value)}
                          value={user.AI_OCompany ?? undefined}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                  <Box width="80%" minWidth="350px">
                    <AIOTextfieldComponent
                      multiline
                      minRows={3}
                      value={user.AI_OCommentary ?? ""}
                      onChange={updateUser("AI_OCommentary")}
                      title={t("auth.registering.commentary")}
                      placeholder={t("auth.registering.commentaryPlaceholder")}
                    />
                  </Box>
                </Stack>
              )}
              {user.type === RegisterUserTypeEnum.NEITHER_AIO_NOR_AI_O && (
                <Stack spacing="21px">
                  <Box width="350px">
                    <AIOTextfieldComponent
                      value={user.missingCompanyName ?? ""}
                      onChange={updateUser("missingCompanyName")}
                      title={t("auth.registering.companyName")}
                      placeholder={t("auth.registering.name")}
                    />
                  </Box>
                  <Box width="80%" minWidth="350px">
                    <AIOTextfieldComponent
                      multiline
                      minRows={3}
                      value={user.missingCompanyCommentary ?? ""}
                      onChange={updateUser("missingCompanyCommentary")}
                      title={t("auth.registering.commentary")}
                      placeholder={t("auth.registering.commentaryPlaceholder")}
                    />
                  </Box>
                </Stack>
              )}
            </Stack>
          </SteppedContent>
        );
      case 3:
        return (
          <SteppedContent padding={breakpointDownMD ? "10px 0px" : "37px 30px"} pb="0px !important">
            <Stack direction="row" alignItems="center" spacing={0.8} px={breakpointDownMD ? "20px" : undefined}>
              <Typography fontSize="20px" fontWeight={700} lineHeight={1.25}>
                {t("auth.registering.yourSubscription")}
              </Typography>
              <Typography fontSize="14px" fontWeight={500} lineHeight={1.25} color={Colors.greyLight}>
                {`(${step}/${totalStep})`}
              </Typography>
            </Stack>

            <Stack spacing="23px" alignItems="center" width={"100%"} mt={breakpointDownMD ? "20px" : undefined}>
              <Typography fontSize={"12px"} fontWeight={300}>
                {t("auth.registering.selectOffer")}
              </Typography>
              <Stack mt="0px !important" alignItems="center" spacing="30px" width={breakpointDownMD ? "100%" : undefined}>
                <Box ml="-23px">
                  <SubscriptionPeriodSwitch checked={!user.isMonthly} onChange={() => updateUser("isMonthly")(!user.isMonthly)} />
                </Box>

                <Stack direction="row" width={"100%"} spacing="30px" px={breakpointDownMD ? "20px" : undefined} overflow={"auto"}>
                  <Stack
                    direction={"row"}
                    flexShrink={0}
                    minHeight={"100%"}
                    alignItems={"stretch"}
                    maxWidth={"85%"}
                    sx={{ scrollSnapAlign: "start" }}>
                    <SubscriptionSmallColumnComponent
                      key={freemiumSubscription.type}
                      handleSelect={() => updateUser("selectedSubscriptionType")(freemiumSubscription.type)}
                      isSelected={user.selectedSubscriptionType === freemiumSubscription.type}
                      isYearly={!user.isMonthly}
                      subscription={freemiumSubscription}
                    />
                  </Stack>
                  <Stack direction={"row"} flexShrink={0} minHeight={"100%"} alignItems={"stretch"} sx={{ scrollSnapAlign: "start" }}>
                    <SubscriptionSmallColumnComponent
                      key={proSubscription.type}
                      handleSelect={() => updateUser("selectedSubscriptionType")(proSubscription.type)}
                      isSelected={user.selectedSubscriptionType === proSubscription.type}
                      isYearly={!user.isMonthly}
                      subscription={proSubscription}
                    />
                  </Stack>
                  <Stack direction={"row"} flexShrink={0} minHeight={"100%"} alignItems={"stretch"} sx={{ scrollSnapAlign: "start" }}>
                    <SubscriptionSmallColumnComponent
                      amount={user.companyOffersNb}
                      unlimited={user.companyUnlimitedOffers}
                      handleAmountChange={(amount, unlimited) => {
                        setUser((state) => ({
                          ...state,
                          companyOffersNb: amount,
                          companyUnlimitedOffers: unlimited,
                        }));
                      }}
                      key={companySubscription.type}
                      handleSelect={() => updateUser("selectedSubscriptionType")(companySubscription.type)}
                      isSelected={user.selectedSubscriptionType === companySubscription.type}
                      isYearly={!user.isMonthly}
                      subscription={companySubscription}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={breakpointDownMD ? "column" : "row"} mt="0px !important" alignItems={"center"} width={"100%"} spacing={"30px"}>
                <Box width={"100%"}>
                  <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                    <Typography fontSize="14px" fontWeight={500} pt="12px">
                      {`${captchaQuestion.num1} ${captchaQuestion.operator} ${captchaQuestion.num2} = ?`}
                    </Typography>
                    <AIOTextfieldComponent
                      required
                      title={t("auth.registering.captcha")}
                      placeholder={t("auth.registering.captchaPlaceholder")}
                      value={captchaAnswer}
                      error={errors.captcha}
                      onChange={(value) => setCaptchaAnswer(value)}
                      sx={{ width: "200px" }}
                    />
                    <IconButton onClick={generateNewCaptcha} size="small">
                      <img src="/images/icon_refresh_primary.svg" alt="refresh" style={{ width: "20px", height: "20px" }} />
                    </IconButton>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </SteppedContent>
        );
      default:
        return <></>;
    }
  };

  return (
    <MainContainer alignItems={"center"} ismobile={breakpointDownMD ? "true" : "false"} overflow={"auto"}>
      <SecondaryContainer height={"100%"} style={breakpointDownMD ? { margin: 0, padding: 0 } : undefined}>
        {!breakpointDownMD && (
          <Stack width={"100%"} direction={"row"}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<img alt="back" src="/images/arrow_back.svg" />}
              onClick={() => navigate(RoutesUnAuth.LOGIN)}>
              <Typography fontWeight="300" color="secondary">
                {t(`auth.registering.back`)}
              </Typography>
            </Button>
          </Stack>
        )}
        <Stack height={"100%"} justifyContent={breakpointDownMD ? undefined : "center"}>
          <Stack spacing={"35px"} height={breakpointDownMD ? "100%" : undefined}>
            {!breakpointDownMD && (
              <Typography fontSize="35px" fontWeight={700} height="35px" lineHeight={1.25}>
                {t(`auth.registering.registration`)}
              </Typography>
            )}
            <SteppedContainer style={breakpointDownMD ? { height: "100%", borderRadius: "0px" } : { overflow: "hidden" }}>
              {breakpointDownMD && (
                <Stack>
                  <Stack width={"100%"} direction={"row"} py={"10px"}>
                    <Button
                      variant="text"
                      color="inherit"
                      startIcon={<img alt="back" src="/images/arrow_back.svg" />}
                      onClick={() => navigate(RoutesUnAuth.LOGIN)}>
                      <Typography fontWeight="300" color="secondary">
                        {t(`auth.registering.back`)}
                      </Typography>
                    </Button>
                  </Stack>
                  <Typography fontSize="35px" fontWeight={700} lineHeight={1.25} px="30px" mt={breakpointDownMD ? "0px" : "20px"}>
                    {t(`auth.registering.registration`)}
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" sx={{ width: "100%", height: "6px", backgroundColor: "#00022F0F", position: "absolute", top: "0px" }}>
                <Stack sx={{ backgroundColor: "#00022F", width: `${(step / totalStep) * 100}%` }} />
              </Stack>
              {getStep()}
              <Stack direction={"row"} justifyContent={"center"} bgcolor={Colors.white} width={"100%"} mt="30px" pb="40px" spacing={"20px"}>
                <AIOButtonComponent
                  title={t(`auth.registering.previous`)}
                  ariaLabel="modal"
                  disabled={step === 1}
                  onClick={handlePrevious}
                  variant="text"
                  color="secondary"
                />
                <AIOButtonComponent
                  title={t(step === totalStep ? `global.finish` : `auth.registering.next`)}
                  ariaLabel="modal"
                  disabled={checkIfErrors(errors[`step${step}`]) || registerLoading || !acceptPolicy}
                  onClick={handleConfirm}
                  variant="contained"
                  color="secondary"
                />
              </Stack>
            </SteppedContainer>
          </Stack>
        </Stack>
      </SecondaryContainer>
      {openSelectAvatar && (
        <AvatarPickerComponent
          disableCustom
          picture={user.picture}
          pictureFile={user.pictureFile}
          handleChangePicture={(file, avatar) => {
            setUser((state) => ({ ...state, pictureFile: file, picture: avatar }));
          }}
          handleClose={() => setOpenSelectAvatar(false)}
        />
      )}
      {showStripe && (
        <StripeModal
          stripePaymentIntendSecret={showStripe}
          title={t("subscriptions.professionalOfferPayment")}
          handleClose={() => {
            setShowStripe(false);
            enqueueSnackbar(t("auth.registering.success.created"), { variant: "success" });
            navigate(`/${RoutesUnAuth.LOGIN}`);
          }}
          returnUrl={`${window.location.origin}/${RoutesUnAuth.LOGIN}`}
        />
      )}
    </MainContainer>
  );
};
export default SignUp;
